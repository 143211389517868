@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Extrabold.woff2') format('woff2'),
        url('OpenSans-Extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold.woff2') format('woff2'),
        url('OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Semibold.woff2') format('woff2'),
        url('OpenSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans.woff2') format('woff2'),
        url('OpenSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Light.woff2') format('woff2'),
        url('OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans.woff2') format('woff2'),
        url('NotoSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-Bold.woff2') format('woff2'),
        url('NotoSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerBlack.woff2') format('woff2'),
        url('MullerBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerExtraBold.woff2') format('woff2'),
        url('MullerExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Bold.woff2') format('woff2'),
        url('Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src:  url('icomoon.eot?foog7v');
    src:  url('icomoon.eot?foog7v#iefix') format('embedded-opentype'),
    url('icomoon.ttf?foog7v') format('truetype'),
    url('icomoon.woff?foog7v') format('woff'),
    url('icomoon.svg?foog7v#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fontello';
    src: url('fontello.eot?55327400');
    src: url('fontello.eot?55327400#iefix') format('embedded-opentype'),
    url('fontello.woff2?55327400') format('woff2'),
    url('fontello.woff?55327400') format('woff'),
    url('fontello.ttf?55327400') format('truetype'),
    url('fontello.svg?55327400#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerRegular.woff2') format('woff2'),
        url('MullerRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?55327400#fontello') format('svg');
  }
}
*/

@font-face {
    font-family: 'fontello';
    src: url('fontello.eot?64658561');
    src: url('fontello.eot?64658561#iefix') format('embedded-opentype'),
    url('fontello.woff2?64658561') format('woff2'),
    url('fontello.woff?64658561') format('woff'),
    url('fontello.ttf?64658561') format('truetype'),
    url('fontello.svg?64658561#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?64658561#fontello') format('svg');
  }
}
*/

@font-face {
    font-family: 'fontello';
    src: url('fontello.eot?54894551');
    src: url('fontello.eot?54894551#iefix') format('embedded-opentype'),
    url('fontello.woff2?54894551') format('woff2'),
    url('fontello.woff?54894551') format('woff'),
    url('fontello.ttf?54894551') format('truetype'),
    url('fontello.svg?54894551#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?54894551#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-phone:before { content: '\e801'; } /* '' */
.icon-email:before { content: '\e802'; } /* '' */
.icon-location:before { content: '\e804'; } /* '' */